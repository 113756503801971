.App {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
}

.turnstile {
  width: 100px;
  height: 100px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: background-color 0.3s;
}

.turnstile.default {
  background-color: #e0e0e0;
}

.turnstile.green {
  background-color: #4CAF50;
}

.data-display {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 1.2em;
}

.reset-button {
  margin-top: 30px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  background-color: #f44336;  /* Red color */
  color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s;
}

.reset-button:hover {
  background-color: #e53935;  /* Darker red on hover */
}
